<template>
  <component :is="component" v-bind="$attrs" @button-clicked="click">
    <slot></slot>
  </component>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';

  const moduleStore = useModuleStore();

  const component = defineAsyncComponent(() => {
    if (moduleStore.mode === 'sis') {
      return import('@base/components/SisButton.vue');
    }
    return import('@base/components/LmsButton.vue');
  });

  const emits = defineEmits(['button-clicked']);

  const click = () => {
    emits('button-clicked');
  };
</script>
