<template>
  <div class="policiesOverlay fade-in" v-show="showPoliciesOverlay">
    <div class="policiesOverlayContainer">
      <div class="policiesContent" v-if="loadingState === 0">
        <JwlLanguageSwitcher />
        <div class="text" v-html="policyText"></div>
        <div class="policiesOverlayRow">
          <div class="mediaPolicy" v-if="mediaPolicy">
            <div v-html="mediaPolicyText"></div>
            <FormCheckboxField name="media" v-model="mediaPolicyAccepted" hide-label />
          </div>
        </div>
        <div class="policiesOverlayRow">
          <div class="announcementPolicy" v-if="announcementPolicy">
            <div v-html="announcementPolicyText"></div>
            <FormCheckboxField name="announcement" v-model="announcementPolicyAccepted" hide-label />
          </div>
        </div>
        <div class="policiesOverlayRow">
          <JwlButton name="policyAccepted" @button-clicked="acceptPolicies">Accept</JwlButton>
          <JwlButton class="jwl-button red" name="policyDismiss" @button-clicked="logout">Dismiss</JwlButton>
        </div>
      </div>
      <div class="policiesLoading" v-if="loadingState === 1">
        <JwlIcon loading-icon margin-right /> {{t('general.loadingData')}}
      </div>
    </div>
  </div>
</template>

<script setup>
  const { t } = useI18n();
  const route = useRoute();
  const router = useRouter();

  const moduleStore = useModuleStore();
  const learningStore = useLearningStore();
  const { currentUser } = useModuleStore();
  const policyId = ref('');
  const loadingState = ref(0);
  const showPoliciesOverlay = ref(true);
  const policyText = ref('');
  const announcementPolicyText = ref('');
  const announcementPolicy = ref(false);
  const announcementPolicyAccepted = ref(true);
  const mediaPolicyText = ref('');
  const mediaPolicy = ref(false);
  const mediaPolicyAccepted = ref(true);

  const getPolicy = () => {
    loadingState.value = 1;
    learningStore.getData('policy-api/current-policy').then((data) => {
      policyText.value = data.policyText;
      policyId.value = data.policyId;
      announcementPolicyText.value = data.announcementPolicyText;
      announcementPolicy.value = data.announcementPolicy;
      mediaPolicyText.value = data.mediaPolicyText;
      mediaPolicy.value = data.mediaPolicy;
      nextTick(() => {
        loadingState.value = 0;
      });
    }).catch(() => {
      // console.log(e);
    });
  };

  const acceptPolicies = () => {
    loadingState.value = 1;
    const formData = new FormData();
    formData.set('id', policyId.value);
    if (announcementPolicy.value) {
      formData.set('announcementPolicyAccepted', JSON.stringify(announcementPolicyAccepted.value));
      formData.set('mediaPolicyAccepted', JSON.stringify(mediaPolicyAccepted.value));
    }
    learningStore.postData({
      url: 'profile/update-user-policy-acception',
      formData,
    }).then((data) => {
      if (data.success === true) {
        currentUser.policyAccepted = data.policyAccepted;
        nextTick(() => {
          loadingState.value = 2;
        });
      } else {
        loadingState.value = 0;
      }
    }).catch(() => {
      // console.log(e);
    });
  };

  const changeLocale = () => {
    getPolicy();
  };

  watch(route, () => {
    changeLocale();
  });

  const logout = () => {
    moduleStore.logOutUser();
    router.push({ name: 'login' });
  };

  onMounted(() => {
    getPolicy();
  });
</script>

<style lang="scss">
@use "@base/styles/variables";
@use "@base/styles/mixins";

.policiesOverlay{
  align-items: flex-start;
  backdrop-filter: blur(4px);
  background-color: transparentize(variables.$black, .2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity .25s;
  width: 100%;
  z-index: 1010;
  &.fade-in{
    opacity: 1;
  }
  .policiesOverlayContainer{
    background-color: variables.$white;
    border-radius: variables.$border-radius;
    box-sizing: border-box;
    margin: 10vh auto;
    max-width: variables.$container-width * 0.6;
    padding: 1.5rem 2rem;
    width: 90%;
    .policiesOverlayRow{
      margin-top: 1.5rem;
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      grid-gap: 0.5rem;
      .jwl-button.red{
        --button-color: var(--color-error);
      }
    }
  }
  h2 {
    @apply text-2xl my-0;
  }
}

</style>
