import { BASE_URL } from '@base/store/JwlApi';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import 'virtual:uno.css';
// eslint-disable-next-line import/order
import { setupCalendar } from '@angelblanco/v-calendar';
import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue3';

const onMathJaxReady = () => {
  const elems = document.getElementsByClassName('math-tex');
  // eslint-disable-next-line no-restricted-syntax
  Array.from(elems).forEach((el) => {
    renderByMathjax(el);
  });
};

initMathJax({
  url: `${BASE_URL}/js/elearning/mathjax/tex-mml-chtml.js`,
  tex: {
    inlineMath: [['\\(', '\\)']],
    displayMath: [['$$', '$$']],
    processEnvironments: true,
    processRefs: true,
    packages: { '[+]': ['mhchem'] },
  },
  options: {
    enableMenu: true,
    menuOptions: {
      settings: {
        renderer: 'CHTML',
      },
    },
  },
  chtml: {
    scale: 1,
    minScale: 0.5,
  },
  svg: {
    scale: 1,
    minScale: 0.5,
  },
  loader: { load: ['[tex]/mhchem'] },
}, onMathJaxReady);

const pinia = createPinia();

// Languages supported by this SPA
const languages = ['en', 'es', 'ar'];
// Languages that have an rtl writing direction
const rtlLanguages = ['ar'];

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  if (!to.params.lang || !languages.includes(to.params.lang)) {
    const lang = window.navigator.language.slice(0, 2);
    const param = languages.includes(lang)
      ? lang
      : 'en';
    const isRtl = rtlLanguages.includes(lang);
    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }
    i18n.locale = param;
    // learningStore.SET_LOCALE(param);

    next({ name: 'home', params: { lang: param } });
  } else if (from.params.lang !== to.params.lang) {
    const { lang } = to.params;
    document.documentElement.lang = lang;
    const isRtl = rtlLanguages.includes(lang);

    i18n.locale = lang;
    // learningStore.SET_LOCALE(lang);

    if (isRtl) {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }

    next();
  } else {
    next();
  }
});

const app = createApp(App);

app.use(setupCalendar, {});

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(MathJax);

app.mount('#app');
