<template>
  <form class="sis__container container"
        ref="submitForm"
  >
    <div class="userDataValidationOverlay fade-in" v-show="showUserDataValidationOverlay">
      <div class="userDataValidationOverlayContainer">
        <div class="userDataValidationOverlayRow">
          {{ t('userDataValidation.infoTop') }}
          <br>
          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ t('formElement.first-name') }}:</div>
          </div>
          <div v-html="currentUser.firstName" class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ t('formElement.middle-name') }}:</div>
          </div>
          <div v-html="currentUser.middleName" class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ t('formElement.last-name') }}:</div>
          </div>
          <div v-html="currentUser.lastName" class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ t('formElement.birth-date') }}:</div>
          </div>
          <div v-html="currentUser.birthDate"
               class="userDataValidationOverlayColumnRight"></div>

          <div class="userDataValidationOverlayColumnLeft">
            <div>{{ t('formElement.email') }}:</div>
          </div>
          <div v-html="currentUser.eMail" class="userDataValidationOverlayColumnRight"></div>
        </div>
        <div class="userDataValidationOverlayRow">
          {{ t('userDataValidation.infoMid') }}
        </div>
        <div class="userDataValidationOverlayRow mb-5">

          <FormPhoneField name="phone" required v-model="currentUser.phoneNumber" />
        </div>

        <FormSelectField v-if="currentUser.residencyStatus !== null" class="mb-5" name="residency-status" :options="RESIDENCY_STATUS" v-model="currentUser.residencyStatus"/>

        <div class="userDataValidationOverlayRow">
          <p v-html="t('userDataValidation.infoBottom')"/>
        </div>
        <div class="userDataValidationOverlayRow" style="align: right">
          <JwlButton name="Accepted" :buttonDisabled="!phoneValid" @button-clicked="acceptUserData">{{ t('userDataValidation.accept-and-save') }}
          </JwlButton>
          <JwlButton class="JwlButton red" name="rememberMe" @button-clicked="goOn">{{ t('userDataValidation.remind-me-next-time') }}

          </JwlButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
  import {
    RESIDENCY_STATUS,
  } from '@base/store/fieldOptions';

  const { t } = useI18n();

  const { currentUser } = storeToRefs(useModuleStore());
  const learningStore = useLearningStore();

  const submitForm = ref();

  const loadingState = ref(0);
  const showUserDataValidationOverlay = ref(true);

  const acceptUserData = () => {
    loadingState.value = 1;

    if (submitForm.value.reportValidity()) {
      const formData = new FormData(submitForm.value);
      formData.append('formattedPhone', currentUser.value.phoneNumber);

      learningStore.postData({
        url: 'profile/update-user-data',
        formData,
      }).then((data) => {
        if (data.success === true) {
          currentUser.value.userDataValidated = data.userDataValidated;
        }
      }).catch(() => {
      });
    }
  };

  const goOn = () => {
    showUserDataValidationOverlay.value = false;
    currentUser.value.userDataValidated = true;
  };

  const phoneValid = computed(() => {
    return currentUser.value.phoneNumber?.startsWith('+');
  });
</script>

<style lang="scss">
@use "@base/styles/variables";
@use "@base/styles/mixins";

.userDataValidationOverlay {
  align-items: flex-start;
  backdrop-filter: blur(4px);
  background-color: transparentize(variables.$black, .2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity .25s;
  width: 100%;
  z-index: 1010;

  &.fade-in {
    opacity: 1;
  }

  .userDataValidationOverlayContainer {
    background-color: variables.$white;
    border-radius: variables.$border-radius;
    box-sizing: border-box;
    margin: 10vh auto;
    max-width: variables.$container-width * 0.6;
    padding: 1.5rem 2rem;
    width: 90%;

    .userDataValidationOverlayColumnLeft {
      float: left;
      width: 20%;
    }

    .userDataValidationOverlayColumnRight {
      float: left;
      width: 75%;
    }

    .userDataValidationOverlayRow {
      margin-top: 1.5rem;
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      grid-gap: 0.5rem;

      .JwlButton.red {
        --button-color: var(--color-error);
      }
    }
  }
}

</style>
