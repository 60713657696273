<template>
  <div>
    <div class="relative" :class="[
      hideLabel ? '' : 'min-w-200px',
      inline ? 'relative' : 'flex max-lg:flex-wrap w-full items-baseline',
      required ? 'is-required' : '',
      !hiddenValue ? 'emtpy' : ''
    ]">
      <FormFieldLabel v-if="!hideLabel" :name="label ?? name" :translate="translate" :inline="inline" :required="required">
        <template v-slot:labelAddition>
          <slot name="addition"></slot>
        </template>
      </FormFieldLabel>
      <VueMultiselect class="select-field relative border-2 border-gray-300 border-solid rounded-1"
                      :class="[
                        inline ? '' : 'important:lg:w-8/10',
                      ]"
                      :name="name"
                      track-by="id"
                      :options="selectOptions"
                      :multiple="multiple"
                      :modelValue="translatedValue"
                      :customLabel="customLabel"
                      :disabled="disabled"
                      :allowEmpty="!required"
                      :required="!!required"
                      :group-label="groupLabel"
                      :group-values="groupValues"
                      :group-select="false"
                      @update:modelValue="updateValue" />
      <input
        class="form-element__hidden-input left-0 pointer-none absolute top-0 invisible -z-1"
        type="text"
        :name="name"
        :value="hiddenValue" />
    </div>
    <slot name="hint">
      <div v-if="fieldHint" class="flex py-2">
        <small v-if="fieldHint && fieldHintTranslate"
               v-html="t(fieldHint, fieldHintParams)" />
        <small v-else-if="fieldHint" v-html="fieldHint" />
      </div>
    </slot>
  </div>
</template>

<script setup>
  import VueMultiselect from 'vue-multiselect';
  import countries from 'i18n-iso-countries/langs/en.json';

  const { t } = useI18n();

  const props = defineProps({
    name: String,
    modelValue: [Array, Object, String, Number],
    options: Array,
    required: Boolean,
    disabled: Boolean,
    translate: {
      type: Boolean,
      default: true,
    },
    translateOptions: {
      type: Boolean,
      default: true,
    },
    country: Boolean,
    multiple: Boolean,
    hideLabel: Boolean,
    inline: Boolean,
    fieldHint: String,
    fieldHintParams: Object,
    fieldHintTranslate: {
      type: Boolean,
      default: true,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    label: String,
  });

  const emits = defineEmits(['update:modelValue']);

  // const value = ref();
  const customLabel = ({ label }) => label;

  const updateValue = (value) => {
    if (Array.isArray(value)) {
      emits('update:modelValue', value.map((v) => v.id));
    } else {
      emits('update:modelValue', value?.id);
    }
  };

  const selectOptions = computed(() => {
    if (props.country) {
      const selectList = [];
      const countryList = countries.countries;
      Object.keys(countryList).forEach((countryCode) => {
        const countryName = countryList[countryCode];
        selectList.push({
          id: countryCode,
          label: Array.isArray(countryName) ? countryName.join(' / ') : countryName,
        });
      });
      return selectList;
    }

    const selectList = [];
    props.options?.forEach((option) => {
      if (option.label) {
        if (props.isGroup) {
          selectList.push({
            id: option.id,
            label: props.translateOptions ? t(option.label.toString()) : option.label.toString(),
            group: option.group,
          });
        } else {
          selectList.push({
            id: option.id,
            label: props.translateOptions ? t(option.label.toString()) : option.label.toString(),
          });
        }
      }
    });
    return props.options;
  });

  const translatedValue = computed(() => {
    if (Array.isArray(props.modelValue)) {
      const values = [];
      props.modelValue.forEach((val) => {
        const found = selectOptions.value?.find((option) => option.id.toString() === val.toString());
        if (found) {
          values.push(found);
        }
      });
      return values;
    }
    if (props.isGroup) {
      let found = null;
      selectOptions.value?.forEach((option) => {
        const groupOption = option.group?.find((groupOpt) => groupOpt.id?.toString() === props.modelValue?.toString());
        if (groupOption) {
          found = groupOption;
        }
      });
      return found;
    }
    return selectOptions.value?.find((option) => option.id?.toString() === props.modelValue?.toString());
  });

  const groupLabel = computed(() => {
    if (props.isGroup) {
      return 'label';
    }
    return undefined;
  });

  const groupValues = computed(() => {
    if (props.isGroup) {
      return 'group';
    }
    return undefined;
  });

  const hiddenValue = computed(() => (typeof props.modelValue === 'object' && !Array.isArray(props.modelValue) ? null : props.modelValue));
</script>

<style lang="scss">
  .select-field.multiselect {
    min-height: unset;
    @apply bg-white;
    &.multiselect--disabled {
      @apply bg-gray-200;
      .multiselect__select {
        @apply bg-gray-200;
      }
    }
    .multiselect__tags {
      @apply pl-3 pr-8 py-2 important:border-0;
      background: unset;
      .multiselect__input, .multiselect__single{
        background: unset;
      }
    }
    .multiselect__spinner{
      background: unset;
    }
    .multiselect__option--selected.multiselect__option--highlight {
      @apply text-black;
    }
    .multiselect__option:after {
      @apply hidden;
    }
  }
</style>
