<template>
  <div class="jwl-error">

    <div class="jwl-error-no_permission" v-if="type === 'noPermission'">

      <jwl-svg icon-name="no-permission" class="jwl-error__image no-scale-svg" />

      <div class="jwl-error__headline">
        <slot name="headline">
          {{$root.$t('network.error.noPermissionError')}}
        </slot>
      </div>

    </div>

    <div class="jwl-error-study_break" v-else-if="type === 'studyBreak'">

      <jwl-svg icon-name="study-break" class="jwl-error__image no-scale-svg" />

      <div class="jwl-error__headline">
        <slot name="headline">
          {{$root.$t('network.error.studyBreakError')}}
        </slot>
      </div>

    </div>

    <div class="jwl-error-default" v-else>

      <jwl-svg icon-name="error" class="jwl-error__image no-scale-svg" />

      <div class="jwl-error__headline">
        <slot name="headline">
          {{$root.$t('network.error.errorOccurred')}}
        </slot>
      </div>

    </div>

    <slot name="error">
      <p v-if="type === 'noPermission'" v-html="$root.$t('network.error.noPermissionSubErrorMessage')"></p>
      <p v-else-if="type !== 'studyBreak'" v-html="$root.$t('network.error.defaultSubErrorMessage')"></p>
      <br/>

      <div class="jwl-error__message" v-html="errorMessage" />

      <div class="jwl-error__message" v-if="errorSubMessage">
        {{errorSubMessage}}
      </div>
    </slot>
  </div>
</template>

<script>
import JwlSvg from './JwlSvg.vue';

export default {
  name: 'jwl-error',
  components: {
    JwlSvg,
  },
  props: {
    jwlError: [Object, Error],
  },
  computed: {
    errorMessage () {
      let { message } = this.jwlError ?? {};

      if (message && this.jwlError.trans) {
        message = this.$root.$t(message);
      } else if (message && message.constructor.name === 'Array') {
        message = message.join(', ');
      } else if (message && message.constructor.name === 'Object') {
        const { error } = message;
        if (error) {
          message = `${error.code}  - ${error.message}`;
        } else {
          const messages = Object.values(this.jwlError.message);
          message = messages.join(', ');
        }
      } else if (!message) {
        // message = this.jwlError.response.statusText;
      }

      return message;
    },
    errorSubMessage () {
      if (import.meta.env.MODE !== 'production' && this.jwlError?.message) {
        const { error } = this.jwlError.message;
        if (error) {
          return error.exception[0].message;
        }
      }
      return null;
    },
    type () {
      if (this.jwlError) {
        const { type } = this.jwlError;
        return type;
      }
      return null;
    },
    /* isHtmlError () {
      return import.meta.env.VITE_NODE_ENV !== 'production' && this.errorMessage.startsWith('<!--');
    }, */
  },
};
</script>

<style lang="scss">
@use "../styles/variables";
@use "../styles/mixins";

.jwl-error {
  border: .1rem solid var(--color-blue);
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  margin: 2rem 0;
  padding: 3rem 4rem;
  width: 100%;
  text-align: center;

  &__headline {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: auto;
    color: var(--color-blue);
  }

  &__image {
    margin: 0 auto 2.5rem;

    @include mixins.mq(variables.$mq-phone) {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
