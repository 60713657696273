import { createWebHistory, createRouter, Route, RouteConfig, RouterView } from 'vue-router';
import {
  forgotPasswordRoute,
  profileRoute, loginOldRoute,
} from '@base/router/routes';
import routerView from '@base/components/jwlRouterView.vue';

const routes: RouteConfig[] = [
  loginOldRoute,
  forgotPasswordRoute,
  {
    path: '/:lang/apply',
    name: 'apply',
    component: () => import('@/views/Application.vue'),
  },
  {
    path: '/:lang/announcement/subscription/:action/:id/:jwlId',
    name: 'announcement_subscription',
    component: () => import('@/views/AnnouncementSubscription.vue'),
  },
  {
    path: '/:lang/public-wbt',
    name: 'public_wbt',
    component: () => import('@/views/PublicWbt.vue'),
  },
  profileRoute,
  {
    path: '/:lang',
    name: 'lang',
    component: routerView,
    redirect: (to: Route) => ({ name: 'home', lang: to.params.lang }),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: 'not-authorised',
        name: 'notAuthorised',
        component: () => import('@base/components/JWL_NotAuthorised.vue'),
      },
      {
        path: 'facilitator-grading/tasks/:classroomId/course/:course',
        name: 'facilitator_grading_tasks',
        beforeEnter: (to, from, next) => {
          if (from.name === 'home') {
            localStorage.removeItem('GRADING_FILTER');
            localStorage.removeItem('currentTaskCode');
            localStorage.removeItem('currentStudentId');
          }
          next();
        },
        component: () => import('@/views/FacilitatorGrading.vue'),
        children: [
          {
            path: 'task/:task/:submission/:jwlId',
            name: 'facilitator_task_grading_submission',
            component: () => import('@/views/FacilitatorGradingSubmission.vue'),
          },
        ],
      },
      {
        path: 'facilitator-grading/students/:classroomId/course/:course/student/:jwlId',
        name: 'facilitator_grading_students',
        beforeEnter: (to, from, next) => {
          if (from.name === 'home') {
            localStorage.removeItem('GRADING_FILTER');
            localStorage.removeItem('currentTaskCode');
            localStorage.removeItem('currentStudentId');
          }
          next();
        },
        component: () => import('@/views/FacilitatorGrading.vue'),
        children: [
          {
            path: 'task/:task/:submission/:jwlId',
            name: 'facilitator_student_grading_submission',
            component: () => import('@/views/FacilitatorGradingSubmission.vue'),
          },
        ],
      },
      {
        path: 'preview',
        name: 'preview',
        component: routerView,
        redirect: { name: 'preview_list' },
        children: [
          {
            path: '',
            name: 'preview_list',
            component: () => import('@/views/CoursePreview.vue'),
          },
          {
            path: ':unit',
            name: 'unit_preview_wrapper',
            component: routerView,
            redirect: { name: 'unit_preview' },
            children: [
              {
                path: '',
                name: 'unit_preview',
                beforeEnter: (to, from, next) => {
                  if (to.params.unitCode && to.params.unitType) {
                    // store.commit('SET_LAST_OPEN_UNIT', { unitCode: to.params.unitCode, unitType: to.params.unitType });
                  }
                  next();
                },
                component: () => import('./views/UnitPreview.vue'),
              },
              {
                path: 'wbt/:wbtIndex',
                name: 'wbt_preview',
                component: () => import('@/views/WbtPreview.vue'),
              },
              {
                path: 'task/:taskId',
                name: 'task_preview',
                component: () => import('@/views/TaskPreview.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'orientation/:courseId',
        name: 'orientation_base',
        component: routerView,
        redirect: { name: 'orientation_course' },
        children: [
          {
            path: '',
            name: 'orientation_course',
            component: () => import('@/views/OrientationCourse.vue'),
          },
          {
            path: 'unit/:unit',
            name: 'orientation_unit_base',
            component: routerView,
            redirect: { name: 'unit_preview' },
            children: [
              {
                path: '',
                name: 'orientation_unit',
                component: () => import('@/views/OrientationUnit.vue'),
              },
              {
                path: 'wbt/:wbtIndex',
                name: 'orientation_unit_wbt',
                component: () => import('@/views/OrientationWbt.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'course',
        name: 'course',
        component: () => import('@/views/Course.vue'),
        redirect: { name: 'home' },
        children: [
          {
            path: ':concentration/list',
            name: 'concentration_home',
            component: () => import('@/views/CourseHome.vue'),
          },
          {
            path: ':concentration/itinerary',
            name: 'concentration_itinerary',
            component: () => import('@/views/Itinerary.vue'),
          },
        ],
      },
      {
        path: 'concentration/:concentration/unit/:unit',
        name: 'unit',
        component: routerView,
        redirect: { name: 'unit_overview' },
        children: [
          {
            path: 'overview',
            name: 'unit_overview',
            beforeEnter: (to, from, next) => {
              // store.commit('SET_LAST_OPEN_UNIT', { unitCode: to.params.unitCode, unitType: to.params.unitType });
              next();
            },
            component: () => import('./views/UnitOverview.vue'),
          },
          {
            path: 'wbt/:wbtIndex',
            name: 'unit_wbt',
            component: () => import('@/views/Wbt.vue'),
          },
          {
            path: 'submit-text/:task',
            name: 'submit_text',
            component: () => import('@/views/SubmitText.vue'),
          },
          {
            path: 'submit-work/:task',
            name: 'submit_work',
            component: () => import('@/views/TaskSubmitWork.vue'),
          },
          {
            path: 'discuss/:task',
            name: 'discuss',
            component: () => import('@/views/TaskDiscuss.vue'),
          },
          {
            path: 'quiz/:task',
            name: 'quiz',
            component: () => import('@/views/TaskQuiz.vue'),
          },
          {
            path: 'task-session/:task',
            name: 'task_session',
            component: () => import('@/views/TaskSession.vue'),
          },
          {
            path: 'preparation-task/:taskId',
            name: 'preparation_task_preview',
            component: () => import('@/views/TaskPreview.vue'),
          },
        ],
      },
      {
        path: 'class',
        name: 'class_root',
        component: routerView,
        redirect: { name: 'home' },
        children: [
          {
            path: ':concentration/gradebook/:termId?',
            name: 'class',
            component: () => import('@/views/Gradebook.vue'),
          },
          {
            path: ':concentration/grade/:course/:classroomId/:task/:jwlId/:termId?',
            name: 'grade',
            component: () => import('@/views/GradeTask.vue'),
          },
          {
            path: ':concentration/turnit-in/:course/:classroomId/:task/:jwlId/:termId?',
            name: 'turnit_in',
            component: () => import('@/views/TurnitIn.vue'),
          },
          {
            path: ':concentration/discuss/:course/:classroomId/:task/:termId?',
            name: 'grade_discussion',
            component: () => import('@/views/TaskDiscuss.vue'),
          },
        ],
      },
      {
        path: 'support',
        name: 'support',
        component: () => import('@/views/Support.vue'),
        redirect: { name: 'support_glossary' },
        children: [
          {
            path: 'glossary',
            name: 'support_glossary',
            component: () => import('@/views/Glossary.vue'),
          },
          {
            path: 'feedback',
            name: 'support_feedback',
            component: () => import('@/views/Feedback.vue'),
          },
          {
            path: 'installapp',
            name: 'support_installapp',
            component: () => import('./views/InstallApp.vue'),
          },
          {
            path: 'ticket-overview',
            name: 'support_overview',
            component: () => import('@/views/TicketOverview.vue'),
          },
          {
            path: 'issue-report',
            name: 'support_issue',
            component: () => import('@/views/IssueReport.vue'),
          },
        ],
      },
      {
        path: 'announcement/:announcementId',
        name: 'announcement',
        component: () => import('@/views/Announcement.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // base: import.meta.env.VITE_BASE_URL,
  routes,
});

export default router;
