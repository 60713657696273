<template>
  <label
    v-if="translate"
    :for="name"
    :class="classes"
    v-html="t(`formElement.${name}`) + labelAddition">
  </label>

  <label v-else :class="classes">
    {{ name }}
    <slot name="labelAddition"></slot>
  </label>
</template>

<script setup>
  const { t } = useI18n();

  const props = defineProps({
    name: String,
    translate: {
      type: Boolean,
      default: true,
    },
    inline: Boolean,
    required: Boolean,
    reverse: {
      type: Boolean,
      default: false,
    },
  });

  const labelAddition = props.required ? ' *' : '';

  const classes = [
    props.inline ? 'absolute text-3 -top-2 left-1 z-1 bg-white px-1' : 'w-full lg:w-2/10 max-lg:mb-1.5',
    props.reverse ? 'important:w-full' : '',
  ];
</script>
