<template>
  <div>
    <div class="relative" :class="[
      hideLabel ? '' : 'min-w-200px',
      inline ? 'relative' : 'flex w-full items-baseline',
      reverse ? 'flex-row-reverse': '',
      required ? 'is-required' : ''
    ]">
      <FormFieldLabel v-if="!hideLabel" :name="label ?? name" :translate="translate" :inline="inline" :reverse="reverse" />
      <div class="form-element__boolean" :class="inline ? '' : ''">
        <input
          ref="checkbox"
          class="form-element__checkbox"
          :name="name"
          :type="type ? type : 'checkbox'"
          :value="modelValue"
          :checked="modelValue"
          :required="required"
          :disabled="disabled"
          @input="updateValue($event)" />
        <div
          class="form-element__boolean-input"
          :class="[
            modelValue ? 'form-element__boolean-input--checked' : '',
            disabled ? 'disabled' : ''
          ]"
          v-if="type !== 'radio'"
          @click="checkbox.click()" >
          <JwlIcon icon-name="check"
                   v-show="modelValue" />
        </div>
      </div>
    </div>
    <slot name="hint">
      <div v-if="fieldHint" class="flex py-2">
        <small v-if="fieldHint && fieldHintTranslate"
               v-html="t(fieldHint, fieldHintParams)"/>
        <small v-else-if="fieldHint" v-html="fieldHint"/>
      </div>
    </slot>
  </div>
</template>
<script setup>
  const { t } = useI18n();

  const props = defineProps({
    name: String,
    type: String,
    modelValue: [InputEvent, String, Number, Boolean],
    required: Boolean,
    disabled: Boolean,
    translate: {
      type: Boolean,
      default: true,
    },
    hideLabel: Boolean,
    inline: Boolean,
    inputClass: String,
    min: Number,
    max: Number,
    fieldHint: String,
    fieldHintParams: Object,
    fieldHintTranslate: {
      type: Boolean,
      default: true,
    },
    label: String,
    reverse: {
      type: Boolean,
      default: false,
    },
  });

  const emits = defineEmits(['update:modelValue']);

  const checkbox = ref();

  const updateValue = (event) => {
    emits('update:modelValue', props.type === 'radio' ? event.target.value : event.target.checked);
  };

</script>

<style lang="scss">
  @use "@base/styles/variables";
  @use "@base/styles/mixins";

  .form-element {
    $root: &;

    &__boolean {
      align-items: center;
      display: grid;
      grid-template-columns: 1.5rem;
      grid-template-rows: 1.5rem;
      justify-content: center;
      margin-right: 1rem;
      order: 0;
    }

    &__checkbox,
    &__boolean-input {
      grid-column: 1;
      grid-row: 1;
    }

    &__boolean-input {
      height: calc(100% - 4px);
      justify-content: center;
      transition: border-color .25s;
      width: calc(100% - 4px);
      @apply flex items-center bg-white border-gray-300 border-solid rounded-1 border-2;

      &:hover {
        border-color: var(--form-border-color-hover);
      }

      &:active {
        border-color: var(--form-border-color-active);
      }

      &.disabled,
      #{$root}--disabled & {
        cursor: not-allowed;
        @apply bg-gray-100;

        &:hover,
        &:active,
        &:focus {
          border-color: var(--color-gray-500);
        }
      }
    }
  }
</style>
