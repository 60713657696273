<template>
  <div class="relative" :class="[
      hideLabel ? '' : 'min-w-200px',
      inline ? '' : 'flex max-lg:flex-wrap w-full items-baseline',
      required ? 'is-required' : ''
    ]">
    <FormFieldLabel v-if="!hideLabel" :name="label ?? name" :translate="translate" :inline="inline" :required="required" />
    <div :class="[
        !inline ? 'w-full lg:w-8/10' : '',
      ]">
      <VueTelInput
        v-model="phoneNumber"
        mode="international"
        :disabled="disabled"
        :inputOptions="{ name, required }"
        validCharactersOnly
        @on-input="updateValue" />
    </div>
  </div>
</template>
<script setup>
  import { VueTelInput } from 'vue-tel-input';

  const props = defineProps({
    name: String,
    type: String,
    modelValue: [InputEvent, String, Number, Boolean],
    required: Boolean,
    disabled: Boolean,
    translate: {
      type: Boolean,
      default: true,
    },
    hideLabel: Boolean,
    inline: Boolean,
    inputClass: String,
    min: Number,
    max: Number,
    label: String,
  });

  const emits = defineEmits(['update:modelValue']);

  const phoneNumber = ref(props.modelValue ?? null);

  const updateValue = (event) => {
    emits('update:modelValue', event);
  };
</script>

<style lang="scss">
  @use 'vue-tel-input.css';

  .vue-tel-input {
    @apply border-2 border-gray-300 border-solid;
    .vti__input.vti__phone {
      @apply text-4 px-3 py-2;
    }
  }
</style>
